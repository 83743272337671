<template>
  <div class="pt-5 bg-dark" style="min-height: 100vh;">
    <!-- <h4 class="text-center mb-3">
      <span class="text-success text-lg">{{
        footballLiveMatch.home_team_name
      }}</span>
      <small class="text-sm text-danger"> VS </small>
      <span class="text-success text-lg">{{
        footballLiveMatch.away_team_name
      }}</span>
    </h4> -->
    <NoticeBar
        class="col-lg-12 col-12 mx-auto notice-bar"
        scrollable
        :text="noticeText"
      />
    <div class="p-1 px-2">
      <div v-for="(server, index) in servers" :key="index">
        <div v-if="footballLink === index">
          <hls-player style="width: 100%; height: 20rem;" :autoplay="true" :muted="true" :controls="true" ref="myPlayer" :source="server.link" v-if="isM3U8Link(server.link)"/>
          <vueFlvPlayer controls autoplay :muted="true" ref="myPlayer" type="flv" style="width: 100%; height: 20rem;" :source="server.link" v-if="!isM3U8Link(server.link)"/>
        </div>
      </div>
      
    </div>
    <div class="p-3 d-flex justify-content-center flex-wrap">
      <button
        class="col-5 btn btn-danger text-md text-uppercase"
        :class="footballLink === index ? 'active' : ''"
        style="margin-top: 1rem !important"
        v-for="(link, index) in servers"
        :key="index"
        @click="changeLink(index)"
      >
        {{ link.reso }}
      </button>
    </div>
  </div>
</template>
<script>
import { NoticeBar} from 'vant';
import { mapActions } from 'vuex';
import 'hls-player/dist/hls-player.css'
import hlsPlayer from 'hls-player'
import vueFlvPlayer from 'vue-flv-player'

export default {
  components: {
    hlsPlayer,
    NoticeBar,
    vueFlvPlayer
  },
  data() {
    return {
      footballLiveMatch: {},
      footballLink: 0,
      servers: [],
      noticeText: 'မဂ်လာပါ Gold 549 App အသုံးပြုသူလူကြီးမင်းများရှင့်၊၊ Gold 549 App  အသုံးပြုသူလူကြီးမင်းများအတွက် မန်ဘာသစ် 100% ၊ နေ့စဉ် 10% ၊ အပတ်စဉ်ဖရီးယူနစ် ငါးသိန်းအပြင် မောင်းကျိုး၊မောင်းအောင်ဘောနပ်များလည်းပေးနေပါတယ်ရှင့်၊၊ သူငယ်ချင်းများကိုမိတ်ဆက်ပြီးလည်း မိတ်ဆက်ဘောနပ် 50% ရယူနိုင်ပြီဖြစ်ကြောင်း သတင်းကောင်းပေးအပ်ပါတယ်ရှင့်၊၊',
    };
  },
  methods: {
    isM3U8Link(link) {
      return link.includes('.m3u8');
    },
    ...mapActions(['fetchUser']),
    changeLink(link) {
      this.footballLink = link;
    },
    processPause(progress) {
      console.log(progress);
    },
    async fetchMatches() {
      try {
        const apiUrl = 'https://aktech.store/api/v1/aktech/live/link';

				// Define your API key
				const apiKey = 'uwejfkyakdsg5s5dghkhskjd';

        const params = {
            id: this.$route.query.index  // Add your desired parameters here
        };

				// Make a GET request using the fetch function with headers
				fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'x-api-key': apiKey // Add the x-api-key header
					},
          body: JSON.stringify(params)
				})
        .then(response => {
        // Check if the request was successful (status code 2xx)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // Parse the JSON response
          return response.json();
        })
        .then(data => {
            // console.log('Response data:', data);
            this.servers = data;
        })
        .catch(error => {
            // Handle errors
            console.error('Error fetching data:', error);
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    this.fetchMatches();
  },
};
</script>

<style scoped>
.btn.active {
  background-color: #BFBFBF !important;
  color: black !important;
  border: none !important;
}
.notice-bar {
  background-color: #F5365B;
  color: white;
  height: 2.8rem !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}
</style>